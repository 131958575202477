<template>
  <div>
    <div @click="$router.go(-1)" v-if="isBackShow"
      style="display: flex;align-items: center;margin-left: 20px;font-size: 20px;cursor: pointer;margin-top: 20px;">
      <a-icon style="font-size: 30px;margin-right: 10px;" type="left-circle" />
      <span>返回</span>
    </div>
    <a-card style="background-color: #fff;">
      <a-row>
        <a-button v-if="hasPermission('SAVE_INVOICE') && isEdit" @click="addInvoice" type="primary">新增发票</a-button>
      </a-row>
      <!-- 创建 -->
      <a-modal title="新增发票" :visible="visible" :confirm-loading="confirmLoading" :maskClosable="false" @ok="handleOk"
        :footer="null" @cancel="visible = false" :width="800">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item :wrapperCol="{ span: 18, offset: 1 }" v-if="form.headingType != 1">
            <div style="cursor: pointer;">
              <a-tooltip>
                <template slot="title">
                  增值税普通发票开给小规模纳税人或者开票资料不齐全的购买人，购买人取得后不可以进行进项税额抵扣。若您还有疑问，建议联系贵司财务确认后再提交开票需求。
                </template>
                <span>增值税普通发票 <a-icon type="question-circle" /></span>
              </a-tooltip>
              <a-tooltip>
                <template slot="title">
                  增值税专用发票开给一般纳税人，申请时需要提供公司名称、税号、地址电话、开户行名称及账号，一般纳税人批复，购买人取得后可以进行进项税额抵扣。增值税专用发票只能开具纸质发票。若您还有疑问，建议联系贵司财务确认后再提交开票需求。
                </template>
                <span style="margin-left:20px;">增值税专用发票 <a-icon type="question-circle" /></span>
              </a-tooltip>
            </div>
          </a-form-model-item>

          <a-form-model-item :wrapperCol="{ span: 18, offset: 1 }" v-if="form.headingType == 1">
            <div style="cursor: pointer;">
              <a-tooltip>
                <template slot="title">
                  增值税普通发票开给小规模纳税人或者开票资料不齐全的购买人，购买人取得后不可以进行进项税额抵扣。若您还有疑问，建议联系贵司财务确认后再提交开票需求。
                </template>
                <span>增值税普通发票 <a-icon type="question-circle" /></span>
              </a-tooltip>
            </div>
          </a-form-model-item>

          <a-form-model-item prop="headingType" label="抬头类型" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-select v-model="form.headingType" style="width: 100%" placeholder="请选择抬头类型">
              <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in headingTypeList"
                :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="invoiceType" ref="invoiceType" label="发票类型" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <div style="display: flex;align-items: center;">
              <a-select v-model="form.invoiceType" style="width: 100%" placeholder="请选择发票类型">
                <a-select-option :disabled="(form.headingType == 1 || form.headingType == 3) && item.id == 2"
                  :value="item.id" :label="item.name" v-for=" (item, index) in invoiceTypeList" :key="index">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </a-form-model-item>

          <div v-if="form.headingType == 1">
            <a-form-model-item ref="invoiceHeading" prop="invoiceHeading" label="发票抬头" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="form.invoiceHeading" placeholder="请输入发票抬头"
                  @blur="() => { $refs.invoiceHeading.onFieldBlur(); }" />
              </div>
            </a-form-model-item>
          </div>
          <!-- form.headingType 1个人 2企业 3事业 -->
          <div v-if="form.headingType != 1">            
            <a-form-model-item ref="invoiceHeading" prop="invoiceHeading" label="发票抬头" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <a-radio-group v-model="headingTypeValue" >
                <a-radio :value="1">搜索公司</a-radio>
                <a-radio :value="2">手动输入公司</a-radio>
              </a-radio-group>
              <div style="display: flex;position: relative;">
                <a-input v-if="headingTypeValue == 2" v-model="form.invoiceHeading" placeholder="请输入发票抬头"
                  @blur="() => { $refs.invoiceHeading.onFieldBlur(); }" />
                <a-select v-if="headingTypeValue == 1" show-search label-in-value :value="searchVal" placeholder="请输入公司名称" style="width: 100%"
                  :filter-option="false" :not-found-content="fetching ? undefined : null" @search="fetchUser"
                  @change="handleGsChange">
                  <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                  <a-select-option v-for="d in searchData" :key="d.Name" @click="selectGs(d)">
                    {{ d.Name }}
                  </a-select-option>
                </a-select>
              </div>
            </a-form-model-item>
            <!-- 企业 -->
            <a-form-model-item v-if="form.headingType != 3" ref="dutyParagraph" prop="dutyParagraph" label="统一社会信用代码"
              :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="form.dutyParagraph" placeholder="统一社会信用代码"
                  @blur="() => { $refs.dutyParagraph.onFieldBlur(); }" />
              </div>
            </a-form-model-item>
            <!-- 事业单位 -->
            <a-form-model-item v-if="form.headingType == 3" label="统一社会信用代码" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="form.dutyParagraph" placeholder="统一社会信用代码"
                  @blur="() => { $refs.dutyParagraph.onFieldBlur(); }" />
              </div>
            </a-form-model-item>

            <a-form-model-item v-if="form.invoiceType == 2" required label="开户行" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }" ref="bank" prop="bank">
              <div style="display: flex;position: relative;">
                <a-input v-model="form.bank" placeholder="请输入开户行" @blur="() => { $refs.bank.onFieldBlur(); }" />
              </div>
            </a-form-model-item>

            <a-form-model-item v-if="form.invoiceType != 2" label="开户行" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="form.bank" placeholder="请输入开户行" />
              </div>
            </a-form-model-item>


            <a-form-model-item v-if="form.invoiceType == 2"  prop="bankAccount" label="开户行帐号"
              :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <a-input v-model="form.bankAccount" placeholder="请输入开户行帐号"
                @blur="() => { $refs.bankAccount.onFieldBlur(); }" />
            </a-form-model-item>

            <a-form-model-item v-if="form.invoiceType != 2" label="开户行帐号" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <a-input v-model="form.bankAccount" placeholder="请输入开户行帐号" />
            </a-form-model-item>


            <a-form-item v-if="form.invoiceType == 2" label="地址" required ref="address" prop="address"
              :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="form.address" placeholder="请输入地址" @blur="() => { $refs.address.onFieldBlur(); }" />
              </div>
            </a-form-item>

            <a-form-item v-if="form.invoiceType != 2" label="地址" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="form.address" placeholder="请输入地址" />
              </div>
            </a-form-item>

            <a-form-model-item v-if="form.invoiceType == 2" label="电话" required :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }" ref="phone" prop="phone">
              <div style="display: flex;position: relative;">
                <a-input v-model="form.phone" placeholder="请输入电话" @blur="() => { $refs.phone.onFieldBlur(); }" />
              </div>
            </a-form-model-item>

            <a-form-model-item v-if="form.invoiceType != 2" label="电话" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="form.phone" placeholder="请输入电话" />
              </div>
            </a-form-model-item>
          </div>
        </a-form-model>

        <div style="display: flex;justify-content: center;">
          <a-button type="primary" @click="handleOk" style="margin-right: 10px;">保存</a-button>
          <!-- <a-button type="primary" @click="ToDetail">立即选品</a-button> -->
        </div>
      </a-modal>

      <!-- 修改 -->
      <a-modal title="编辑发票" :visible="EditVisible" :confirm-loading="confirmLoading" :maskClosable="false"
        @ok="handleOk" :footer="null" @cancel="EditVisible = false" :width="800">
        <a-form-model ref="ruleForm" :model="editObj" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-form-model-item prop="headingType" label="抬头类型" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-select @change="checkoutHeading" v-model="editObj.headingType" style="width: 100%" placeholder="请选择抬头类型">
              <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in headingTypeList"
                :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="invoiceType" ref="invoiceType" label="发票类型" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-select v-model="editObj.invoiceType" style="width: 100%" placeholder="请选择发票类型">
              <a-select-option :disabled="editObj.headingType != 2 && item.id == 2" :value="item.id" :label="item.name"
                v-for=" (item, index) in invoiceTypeList" :key="index">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <div v-if="editObj.headingType == 1">
            <a-form-model-item ref="invoiceHeading" prop="invoiceHeading" label="发票抬头" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              
              <div style="display: flex;position: relative;">
                <a-input v-model="editObj.invoiceHeading" placeholder="请输入发票抬头"
                  @blur="() => { $refs.invoiceHeading.onFieldBlur(); }" />
              </div>
            </a-form-model-item>
          </div>



          <div v-if="editObj.headingType != 1">

            <a-form-model-item ref="invoiceHeading" prop="invoiceHeading" label="发票抬头" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <a-radio-group v-model="headingTypeValue" >
                <a-radio :value="1">搜索公司</a-radio>
                <a-radio :value="2">手动输入公司</a-radio>
              </a-radio-group>
              <div style="display: flex;position: relative;">
                <a-input v-if="headingTypeValue == 2" v-model="editObj.invoiceHeading" placeholder="请输入发票抬头"
                  @blur="() => { $refs.invoiceHeading.onFieldBlur(); }" />
                <a-select v-if="headingTypeValue == 1" show-search label-in-value :value="searchVal" placeholder="请输入公司名称" style="width: 100%"
                  :filter-option="false" :not-found-content="fetching ? undefined : null" @search="fetchUser"
                  @change="handleGsChange">
                  <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                  <a-select-option v-for="d in searchData" :key="d.Name" @click="selectGs(d)">
                    {{ d.Name }}
                  </a-select-option>
                </a-select>
              </div>
            </a-form-model-item>
            <!-- form.headingType 1个人 2企业 3事业 -->
            <a-form-model-item v-if="editObj.headingType == 2" ref="dutyParagraph" prop="dutyParagraph" label="统一社会信用代码"
              :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="editObj.dutyParagraph" placeholder="请输入统一社会信用代码"
                  @blur="() => { $refs.dutyParagraph.onFieldBlur(); }" />
              </div>
            </a-form-model-item>
            <!-- 事业单位 -->
            <a-form-model-item v-if="editObj.headingType == 3" label="统一社会信用代码" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="editObj.dutyParagraph" placeholder="统一社会信用代码"
                  @blur="() => { $refs.dutyParagraph.onFieldBlur(); }" />
              </div>
            </a-form-model-item>


            <a-form-model-item v-if="editObj.invoiceType == 2" label="开户行" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }" ref="bank" prop="bank">
              <div style="display: flex;position: relative;">
                <a-input v-model="editObj.bank" placeholder="请输入开户行" @blur="() => { $refs.bank.onFieldBlur(); }" />
              </div>
            </a-form-model-item>

            <a-form-model-item v-if="editObj.invoiceType != 2" label="开户行" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="editObj.bank" placeholder="请输入开户行" />
              </div>
            </a-form-model-item>


            <a-form-model-item v-if="editObj.invoiceType == 2"  prop="bankAccount" label="开户行帐号"
              :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <a-input v-model="editObj.bankAccount" placeholder="请输入开户行帐号"
                @blur="() => { $refs.bankAccount.onFieldBlur(); }" />
            </a-form-model-item>

            <a-form-model-item v-if="editObj.invoiceType != 2" label="开户行帐号" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <a-input v-model="editObj.bankAccount" placeholder="请输入开户行帐号" />
            </a-form-model-item>


            <a-form-item label="地址" v-if="editObj.invoiceType == 2" required ref="address" prop="address"
              :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="editObj.address" placeholder="请输入地址" @blur="() => { $refs.address.onFieldBlur(); }" />
              </div>
            </a-form-item>

            <a-form-item label="地址" v-if="editObj.invoiceType != 2" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="editObj.address" placeholder="请输入地址" />
              </div>
            </a-form-item>


            <a-form-model-item v-if="editObj.invoiceType == 2" label="电话" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }" ref="phone" prop="phone">
              <div style="display: flex;position: relative;">
                <a-input v-model="editObj.phone" placeholder="请输入电话" @blur="() => { $refs.phone.onFieldBlur(); }" />
              </div>
            </a-form-model-item>

            <a-form-model-item v-if="editObj.invoiceType != 2" label="电话" :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 18, offset: 1 }">
              <div style="display: flex;position: relative;">
                <a-input v-model="editObj.phone" placeholder="请输入电话" />
              </div>
            </a-form-model-item>
          </div>
        </a-form-model>

        <div style="display: flex;justify-content: center;">
          <a-button type="primary" @click="handleEdit" style="margin-right: 10px;">保存</a-button>
        </div>
      </a-modal>

      <div style="margin-top: 20px;">
        <a-table :columns="columns" :dataSource="dataSource" :loading="tabLoading" :pagination="false" bordered
          :scroll="{ x: 1500 }">
          <template slot="createTime" slot-scope="text">{{ formatDate(text) }}</template>
          <template slot="expectedProcurementTime" slot-scope="text">
            <span v-if="text">{{ formatDate(text) }}</span>
            <span v-if="editObj.invoiceType != 2">-</span>
          </template>
          <template slot="staffName" slot-scope="text">
            <p v-if="text">{{ text }}</p>
            <p v-if="editObj.invoiceType != 2">-</p>
          </template>

          <template slot="name" slot-scope="text">
            <a-popover>
              <template slot="content">
                {{ text }}
              </template>
              {{ text }}
            </a-popover>
          </template>
          <template slot="customerName" slot-scope="text">
            <a-popover>
              <template slot="content">
                {{ text }}
              </template>
              {{ text }}
            </a-popover>
          </template>


          <template slot="action" slot-scope="record">
            <div v-if="isEdit">
              <div>
                <a @click="editChange(record)" v-if="hasPermission('UPDATE_INVOICE')" style="margin-right: 10px;">编辑</a>
                <!-- <a-popconfirm style="cursor: pointer;" title="您确定要删除吗？" ok-text="是" cancel-text="否"
                  @confirm="delConfirm(record)" v-if="hasPermission('DEL_INVOICE')">删除</a-popconfirm> -->
              </div>
            </div>
          </template>
        </a-table>
        <div class="page-box">
          <a-pagination :total="total" :current="currentPage" :pageSize="queryData.size" @change="handlePageChange" />
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import debounce from 'lodash/debounce';
const columns = [
  {
    title: '抬头类型',
    dataIndex: 'headingTypeName',
    scopedSlots: { customRender: 'headingTypeName' },
    align: 'center',
    width: 120,
  },
  {
    title: '发票类型',
    dataIndex: 'invoiceTypeName',
    scopedSlots: { customRender: 'invoiceTypeName' },
    align: 'center',
    width: 150

  },
  {
    title: '发票抬头',
    dataIndex: 'invoiceHeading',
    align: 'center',
    scopedSlots: { customRender: 'invoiceHeading' },
  },
  {
    title: '统一社会信用代码',
    dataIndex: 'dutyParagraph',
    align: 'center',
    width: 200
  },
  {
    title: '开户行',
    dataIndex: 'bank',
    align: 'center',
    width: 140
  },
  {
    title: '开户行帐号',
    dataIndex: 'bankAccount',
    align: 'center',
    width: 180
  },
  {
    title: '地址',
    dataIndex: 'address',
    scopedSlots: { customRender: 'address' },
    align: 'center',
  },
  {
    title: '电话',
    dataIndex: 'phone',
    scopedSlots: { customRender: 'phone' },
    align: 'center',
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    fixed: 'right',
    width: 160
  }
]

export default {
  name: 'QueryList',
  props: {
    isBackShow: {
      type: Boolean,
      default: true
    },
    //是否显示新增按钮
    isEdit: {
      type: Boolean,
      default: false
    },
    selectId: {
      type: String,
      default: ''
    },
    invoiceData: {
      type: Object,
      default: null
    }
  },
  data() {
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 900);
    return {
      searchData: [],
      searchVal: {},
      fetching: false,
      iptmd: 4,
      iptsm: 22,
      moment,
      customerData: [],
      userData: [],
      advanced: true,
      columns: columns,
      dataSource: [],
      tabLoading: false,
      selectedRows: [],
      total: 0,

      headingTypeList: [{ name: '企业', id: 2 }, { name: '个人', id: 1 }, { name: '事业单位', id: 3 }],
      invoiceTypeList: [{ name: '增值税专用发票', id: 2, isShow: true }, { name: '增值税普通发票', id: 1 }],
      // 筛选
      queryData: {
        page: 0,
        size: 10,
        name: '',//方案名称
        minProductBudget: '',
        maxProductBudget: '',//产品预算
        minProductPrice: '', //产品价格
        maxProductPrice: '',
        customerIdList: [],//选择客户
        dateMax: '',
        dateMin: '',
      },
      currentPage: 1,
      // 创建表单
      form: {
        "customerId": "",
        "headingType": '',
        "invoiceType": '',
        "invoiceHeading": "",
        "dutyParagraph": "",
        "bank": "",
        "bankAccount": "",
        "address": "",
        "phone": ""
      },
      // 修改表单
      editObj: {},

      // 新建方案
      visible: false,
      confirmLoading: false,
      // 修改方案
      EditVisible: false,
      // 新建方案弹窗
      labelCol: { span: 8 },
      wrapperCol: { span: 24 },
      headingTypeValue:1,
      rules: {
        headingType: [{ required: true, message: '请选择抬头', trigger: 'change', }],
        invoiceType: [{ required: true, message: '请选择发票类型', trigger: 'change' }],
        invoiceHeading: [{ required: true, message: '请输入发票抬头', trigger: 'blur' }],
        dutyParagraph: [{ required: true, message: '请输入税号', trigger: 'blur' }],
        bank: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
        bankAccount: [{ required: true, message: '请输入开户行帐号', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入电话', trigger: 'blur' }],
      },
    }
  },

  authorize: {
    deleteRecord: 'delete'
  },
  mounted() {
    this.getData()
  },
  computed: {
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  watch: {
    selectId: {
      immediate: true, // 立即执行一次
      handler() {
        this.getData()
      }
    }
  },
  methods: {
    checkoutHeading(e) {
      console.log(e, 'eee')
      this.searchVal.key = ""
      this.editObj.dutyParagraph = ""
      this.editObj.invoiceHeading = ""
    },
    selectGs(data) {
      this.searchVal.key = data.Name;
      if (this.EditVisible) {
        this.editObj.invoiceHeading = data.Name;
        this.editObj.address = data.Address;
        this.editObj.dutyParagraph = data.CreditCode;
      }
      if (this.visible) {
        this.form.invoiceHeading = data.Name;
        this.form.address = data.Address;
        this.form.dutyParagraph = data.CreditCode;
      }

    },
    fetchUser(value) {
      if (!value) {
        return false;
      }
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.searchData = [];
      this.fetching = true;
      // 使用 request 函数从指定接口获取数据
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/findEnterpriseByName/' + value, 'get').then(res => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return;
        }
        console.log(res, 'res')
        if(res.data.code == 200) {
          this.searchData = res.data.data;
        } else {
          this.$message.error(res.data.message)
        }
        this.fetching = false;
      })
        .catch(error => {
          console.error('Error fetching user:', error);
          // 处理错误
        });
    },
    handleGsChange(searchVal) {
      Object.assign(this, {
        searchVal,
        searchData: [],
        fetching: false,
      });
    },
    addInvoice() {
      this.visible = true;
      this.headingTypeValue = 1;
      this.form = {
        invoiceHeading:this.invoiceData.name,
        dutyParagraph:this.invoiceData.creditCode
      };
      // this.form.invoiceHeading = this.invoiceData.name;
      // this.form.dutyParagraph = this.invoiceData.creditCode;
      this.searchVal = {
        key:this.invoiceData.name
      }
      // this.searchVal.key = this.invoiceData.name;
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },

    handleEditCanel() {
      this.EditVisible = false
    },
    handleEdit(e) {
      e.preventDefault()
      this.headingTypeValue = 1;
      // headingType 1个人 2企业 3事业
      if (this.editObj.invoiceType == 2 && this.editObj.headingType != 2) {
        this.$message.error('事业单位/个人 不可选专用发票！')
        return false;
      }
      this.$refs.ruleForm.validate(valid => {
        if (this.editObj.headingType == 2 || !this.editObj.headingType) {
          if (this.editObj.invoiceType == 2) {
            if (!this.editObj.bank || !this.editObj.bankAccount || !this.editObj.address || !this.editObj.phone) {
              this.$message.error('请补全信息！')
              return false;
            }
          }

        }
        if (valid) {
          this.editObj.customerId = this.selectId

          if (this.editObj.headingType == 1) {
            this.editObj = {
              headingType: 1,
              headingTypeName: '个人',
              invoiceHeading: this.editObj.invoiceHeading,
              invoiceType: this.editObj.invoiceType,
              customerId: this.selectId,
              id: this.editObj.id,
            }
          }
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoice/update', 'post', this.editObj).then(res => {
            if (res.data.code == 200) {
              this.$message.success('编辑成功！')
              this.EditVisible = false;
              this.getData()
            }
          })
        } else {
          return false;
        }
      });
    },

    // 编辑
    editChange(e) {
      
      this.editObj = JSON.parse(JSON.stringify(e));
      this.editObj.expectedProcurementTime = this.formatDate(e.expectedProcurementTime)
      this.EditVisible = true;
      this.searchVal= {
        key :this.editObj.invoiceHeading
      };

    },

    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      return result
    },

    handleOk(e) {
      e.preventDefault();
      if (this.form.invoiceType == 2 && this.form.headingType != 2) {
        this.$message.error('事业单位/个人 不可选专用发票！')
        return false;
      }
      // 个人发票 去掉税号
      if (this.form.headingType == 1) {
        delete this.form.dutyParagraph
      }
      if (this.form.headingType == 2 || !this.form.headingType) {
        this.$refs.ruleForm.validate(valid => {
          if (this.form.invoiceType == 2) {
            if (!this.form.bank || !this.form.bankAccount || !this.form.address || !this.form.phone) {
              this.$message.error('请补全信息！')
              return false;
            }
          }

          if (valid) {
            this.form.customerId = this.selectId
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoice/save', 'post', this.form).then(res => {
              if (res.data.code == 200) {
                this.$message.success('添加成功！')
                this.visible = false;
                this.getData()
                this.form = {}
              }
            })
          } else {
            return false;
          }
        });
      } else {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.form.customerId = this.selectId
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoice/save', 'post', this.form).then(res => {
              if (res.data.code == 200) {
                this.$message.success('添加成功！')
                this.visible = false;
                this.getData()
              }
            })
          }
        })
      }

    },
    // 是否确认删除
    delConfirm(e) {
      this.$message.loading();
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoice/' + e.id, 'delete').then(res => {
        if (res.data.code == 200) {
          this.$message.success('删除成功')
          this.getData()
        }
      })
    },
    resetQuery() {
      this.queryData = {
        page: 0,
        size: 10,
        name: '',
        minProductBudget: '',
        maxProductBudget: '',//产品预算
        minProductPrice: '', //产品价格
        maxProductPrice: '',
        customerIdList: [],//选择客户
        dateMax: '',
        dateMin: '',
      }
      this.getData()
    },

    // 获取列表 
    getData() {
      this.tabLoading = true;
      if (!this.selectId) return;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoice/list/' + this.selectId, 'get').then(res => {
        let data = res.data.data;
        this.tabLoading = false;
        if (res.data.code == 200) {
          this.dataSource = data;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}


.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}


@media (max-width: 1800px) {
  ::v-deep .ant-card-body {
    padding: 12px !important;
    // zoom: .8 !important;
  }

  ::v-deep .ant-col-md-4 {
    // width: 16% !important;
    // margin-right: 20px;
  }
}


@media (max-width: 1600px) {
  ::v-deep .ant-col-md-4 {
    width: 18% !important;
  }
}
</style>
